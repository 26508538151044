import {
  DocsMenuSVG,
  PrivacyPolicyMenuSVG,
  StableCoinsMenuSVG,
  SwapMenuSVG,
  TermsOfServiceSVG,
  UsTreasuriesMenuSVG,
} from '../assets/svg/menu'

export interface MenuCategoryType {
  categoryName: string
  categoryId: string
  children: MenuItemType[]
}

export interface MenuItemType {
  title: string
  path: string
  icon: JSX.Element
  isExternal?: boolean
  isMinifiedSidebar?: boolean
}

export const menuItems: MenuCategoryType[] = [
  {
    categoryName: 'Trade',
    categoryId: 'trade',
    children: [
      {
        title: 'Swap',
        path: '/swap',
        icon: <SwapMenuSVG />,
      },
    ],
  },
  {
    categoryName: 'Explore',
    categoryId: 'explore',
    children: [
      {
        title: 'U.S. Treasuries',
        path: '/explorer/us-treasuries',
        icon: <UsTreasuriesMenuSVG />,
        isMinifiedSidebar: true,
      },
      {
        title: 'Stablecoins',
        path: '/explorer/stable-coins',
        icon: <StableCoinsMenuSVG />,
        isMinifiedSidebar: true,
      },
    ],
  },
  {
    categoryName: 'More',
    categoryId: 'more',
    children: [
      {
        title: 'Docs',
        path: 'https://docs.newera.finance/',
        icon: <DocsMenuSVG />,
        isExternal: true,
      },
      {
        title: 'Terms of Service',
        path: 'https://docs.newera.finance/legal/terms-of-service',
        icon: <TermsOfServiceSVG />,
        isExternal: true,
      },
      {
        title: 'Privacy Policy',
        path: 'https://docs.newera.finance/legal/privacy-policy',
        icon: <PrivacyPolicyMenuSVG />,
        isExternal: true,
      },
    ],
  },
]
