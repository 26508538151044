export const baseUsStables = [
  {
    symbol: 'USDC',
    name: 'Circle',
    address: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
    chainId: 8453,
    logoURI: '/icons/USDC.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'USDBC',
    name: 'USD Base Coin',
    address: '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca',
    chainId: 8453,
    logoURI: '/icons/USDC.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'USDz',
    name: 'Anzen',
    address: '0x04d5ddf5f3a8939889f11e97f8c4bb48317f1938',
    chainId: 8453,
    logoURI: '/icons/usdz.webp',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'USDT',
    name: 'Tether',
    address: '0xfde4c96c8593536e31f229ea8f37b2ada2699bb2',
    chainId: 8453,
    logoURI: '/icons/USDT.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'EUROC',
    name: 'Circle EURO',
    address: '0x60a3e35cc302bfa44cb288bc5a4f316fdb1adb42',
    chainId: 8453,
    logoURI: '/icons/eth/EUROC Circle.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'Dai',
    name: 'DAI',
    address: '0x50c5725949a6f0c72e6c4a641f24049a917db0cb',
    chainId: 8453,
    logoURI: '/icons/DAI.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
]

export const baseUstBills = [
  {
    symbol: 'USDM',
    name: 'Mountain Protocol',
    address: '0x59d9356e565ab3a36dd77763fc0d87feaf85508c',
    chainId: 8453,
    logoURI: '/icons/USDM.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
]

export const baseUtils = [
  {
    symbol: 'ETHB',
    name: 'Ethereum on base',
    address: '0x8d285Df5bE4570097E84241090FCa8034AB18547',
    chainId: 8453,
    logoURI: '/icons/ETH.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'WETH',
    name: 'Wrapped Ether',
    address: '0x4200000000000000000000000000000000000006',
    chainId: 8453,
    logoURI: '/icons/ETH.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'LINK',
    name: 'Chainlink',
    address: '0x88fb150bdc53a65fe94dea0c9ba0a6daf8c6e196',
    chainId: 8453,
    logoURI: '/icons/LINK.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'cbBTC',
    name: 'Coinbase Wrapped BTC',
    address: '0xcbB7C0000aB88B473b1f5aFd9ef808440eed33Bf',
    chainId: 8453,
    logoURI: '/icons/cbbtc.png',
    decimals: 8,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'UNI',
    name: 'Uniswap',
    address: '0xc3de830ea07524a0761646a6a4e4be0e114a3c83',
    chainId: 8453,
    logoURI: '/icons/UNI.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'MKR',
    name: 'Maker',
    address: '0x30F16E3273AB6e4584B79B76fD944E577e49a5c8',
    chainId: 8453,
    logoURI: '/icons/maker.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
]
