export const polyUsStables = [
  {
    symbol: 'USDC',
    name: 'Circle',
    address: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
    chainId: 137,
    logoURI: '/icons/USDC.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'BRLA',
    name: 'Brazilian Real BRLA Token',
    address: '0xe6a537a407488807f0bbeb0038b79004f19dddfb',
    chainId: 137,
    logoURI: '/icons/brla.webp',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'AUDF',
    name: 'Forte AUD',
    address: '0xd2a530170d71a9cfe1651fb468e2b98f7ed7456b',
    chainId: 137,
    logoURI: '/icons/audf.webp',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'USDT',
    name: 'Tether',
    address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    chainId: 137,
    logoURI: '/icons/USDT.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'Dai',
    name: 'DAI',
    address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    chainId: 137,
    logoURI: '/icons/DAI.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
]

export const polyUstBills = [
  {
    symbol: 'USDM',
    name: 'Mountain Protocol',
    address: '0x59d9356e565ab3a36dd77763fc0d87feaf85508c',
    chainId: 137,
    logoURI: '/icons/USDM.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
]

export const polyUtils = [
  {
    symbol: 'POL',
    name: 'Polygon',
    address: '0x0000000000000000000000000000000000001010',
    chainId: 137,
    logoURI: '/icons/pol.webp',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'WMATIC',
    name: 'Wrapped MATIC',
    address: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    chainId: 137,
    logoURI: '/icons/pol.webp',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'WBTC',
    name: 'Wrapped BTC',
    address: '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
    chainId: 137,
    logoURI: '/icons/btc.webp',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'LINK',
    name: 'Chainlink',
    address: '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
    chainId: 137,
    logoURI: '/icons/LINK.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'UNI',
    name: 'Uniswap',
    address: '0xb33eaad8d922b1083446dc23f610c2567fb5180f',
    chainId: 137,
    logoURI: '/icons/UNI.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'MKR',
    name: 'Maker',
    address: '0x6f7c932e7684666c9fd1d44527765433e01ff61d',
    chainId: 137,
    logoURI: '/icons/maker.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'LDO',
    name: 'Lido DAO',
    address: '0xc3c7d422809852031b44ab29eec9f1eff2a58756',
    chainId: 137,
    logoURI: '/icons/LDO.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'SMT',
    name: 'Swarm Markets',
    address: '0xe631dabef60c37a37d70d3b4f812871df663226f',
    chainId: 137,
    logoURI: '/icons/smt.webp',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
]
