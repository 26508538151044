import { ReactComponent as ArrowUp } from 'assets/svg/arrow-up.svg'
import clsx from 'clsx'
import { cloneElement, FC, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MenuCategoryType } from 'utils/menuItems'

interface MenuItemProps {
  categoryItem: MenuCategoryType
  isClosed: boolean
  onItemClicked: () => void
}

const MenuItem: FC<MenuItemProps> = ({ categoryItem, isClosed, onItemClicked }) => {
  const [isActive, setIsActive] = useState(true)
  const [height, setHeight] = useState<number | undefined>(0)
  const contentRef = useRef<HTMLDivElement>(null)
  const location = useLocation()

  const toggleActive = () => {
    setIsActive((prev) => !prev)
  }

  useEffect(() => {
    if (contentRef.current) {
      const scrollHeight = contentRef.current.scrollHeight
      setHeight(isActive ? scrollHeight : 0)
    }
  }, [isActive, categoryItem.children])

  return (
    <div className="mt-6">
      <div className="flex justify-between items-center opacity-30 cursor-pointer px-4 h-5" onClick={toggleActive}>
        {!isClosed && <p className="text-sm font-medium">{categoryItem.categoryName}</p>}
        <ArrowUp className={`transition-transform duration-200 ${isActive ? 'rotate-180' : ''}`} />
      </div>

      <div ref={contentRef} style={{ height }} className="transition-[height] duration-300 overflow-hidden">
        {categoryItem.children?.length ? (
          <ul className="">
            {categoryItem.children.map((item) => {
              const isActivePath = item.path === location.pathname
              const LinkComponent = item.isExternal ? 'a' : Link
              return (
                <li
                  key={item.path}
                  className={clsx(
                    'text-white cursor-pointer hover:opacity-100 transition-opacity duration-200 relative',
                    {
                      'opacity-50': !isActivePath,
                    }
                  )}
                >
                  <LinkComponent
                    onClick={onItemClicked}
                    className={clsx('text-white flex items-center py-2.5 h-[44px]', {
                      'px-4': !isClosed,
                      'px-3.5': isClosed,
                    })}
                    to={item.path}
                    {...(item.isExternal ? { href: item.path, target: '_blank', rel: 'noreferrer' } : {})}
                  >
                    <div
                      className={clsx(
                        'h-4 absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-[50%] blur-[9px] opacity-20 transition-transform duration-300',
                        {
                          '-translate-x-1/2': isActivePath,
                          '-translate-x-96': !isActivePath,
                          'w-24': isClosed,
                          'w-96': !isClosed,
                        }
                      )}
                    ></div>

                    {cloneElement(item.icon, {
                      className: 'mr-2 flex-shrink-0',
                    })}
                    {!isClosed && <p className="text-nowrap">{item.title}</p>}
                  </LinkComponent>
                </li>
              )
            })}
          </ul>
        ) : null}
      </div>
    </div>
  )
}

export default MenuItem
