import clsx from 'clsx'
import { cloneElement, FC, Fragment, ReactElement, useState } from 'react'

export interface ColumnsDataType<T> {
  name: string
  key: string
  render: (data: T) => string | JSX.Element
  comingSoon?: boolean
}

interface ExplorerTableProps {
  columnsData: ColumnsDataType<any>[]
  data: any[]
  details?: ReactElement
}

const ExplorerTable: FC<ExplorerTableProps> = ({ columnsData, data, details: DetailsComponent }) => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null)

  const handleRowClick = (index: number) => {
    if (!DetailsComponent) return
    setExpandedRow((prev) => (prev === index ? null : index))
  }

  return (
    <div className="w-full overflow-x-auto h-[inherit]">
      <table className="w-full" style={{ borderSpacing: '0 4px', borderCollapse: 'separate' }}>
        <thead className="max-sm:hidden">
          <tr className="h-[54px]">
            {columnsData.map((column, index) => (
              <th
                key={column.key}
                className={clsx('relative text-left p-3 text-sm font-normal bg-[#FFFFFF05]', {
                  'rounded-t-xl sm:rounded-l-xl': index === 0,
                  'rounded-b-xl sm:rounded-r-xl': index === columnsData.length - 1,
                  'min-w-28': index !== 0,
                })}
              >
                <span className="opacity-50">{column.name}</span>
                {column.comingSoon && (
                  <span className="text-white bg-black text-[12px] rounded-[12px] rounded-bl-none absolute -top-2 left-1/2 -translate-x-1/2 px-2">
                    Soon
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="sm:space-x-4 max-sm:space-x-0 max-sm:space-y-2">
          {data.map((rowData, index) => (
            <Fragment key={index}>
              <tr
                key={index}
                className={clsx(
                  'max-sm:flex max-sm:flex-col max-sm:m-0 cursor-pointer transition-all duration-300 ease-in-out',
                  {
                    'bg-[#FFFFFF05]': expandedRow !== index,
                    'bg-[#FFFFFF10]': expandedRow === index,
                  }
                )}
                onClick={() => handleRowClick(index)}
              >
                {/* Render the row data */}
                {columnsData.map((column, innerIndex) => (
                  <td
                    key={column.key}
                    className={clsx('p-3 max-sm:w-full max-sm:flex justify-between items-center flex-wrap', {
                      'rounded-t-xl sm:rounded-l-xl': innerIndex === 0,
                      'rounded-b-xl sm:rounded-r-xl': innerIndex === columnsData.length - 1 && expandedRow !== index,
                    })}
                  >
                    {column.key === 'category' || column.key === 'logo' || column.key === 'operations' ? null : (
                      <span className="hidden max-sm:block opacity-40">{column.key}</span>
                    )}
                    {column.render(rowData)}
                  </td>
                ))}
              </tr>

              <tr
                className={clsx('transition-all duration-300 ease-in-out overflow-hidden', {
                  'max-h-0': expandedRow !== index,
                  'max-h-[500px]': expandedRow === index,
                })}
              >
                <td
                  colSpan={columnsData.length}
                  className={clsx('duration-150 transition-all ease-in-out', {
                    'p-3 h-max opacity-100': expandedRow === index,
                    'p-0 h-0 opacity-0': expandedRow !== index,
                  })}
                >
                  {DetailsComponent &&
                    expandedRow === index &&
                    cloneElement(DetailsComponent, {
                      data: rowData,
                      className: clsx({ 'h-auto': expandedRow === index, 'h-0': expandedRow !== index }),
                    })}
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ExplorerTable
