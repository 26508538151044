export const gnosisUsStables = [
  {
    symbol: 'USDC',
    name: 'Circle',
    address: '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83',
    chainId: 100,
    logoURI: '/icons/USDC.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'USDT',
    name: 'Tether',
    address: '0x4ECaBa5870353805a9F068101A40E0f32ed605C6',
    chainId: 100,
    logoURI: '/icons/USDT.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'Dai',
    name: 'DAI',
    address: '0x44fA8E6f47987339850636F88629646662444217',
    chainId: 100,
    logoURI: '/icons/DAI.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'EURe',
    name: 'Monerium EUR emoney',
    address: '0xcB444e90D8198415266c6a2724b7900fb12FC56E',
    chainId: 100,
    logoURI: '/icons/eure.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
]

export const gnosisStocks = [
  {
    symbol: 'bCSPX',
    name: 'Backed CSPX Core S&P500',
    address: '0x1e2c4fb7ede391d116e6b41cd0608260e8801d59',
    chainId: 100,
    logoURI: '/icons/bcspx.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'bCOIN',
    name: 'Backed Coinbase',
    address: '0xbbcb0356bb9e6b3faa5cbf9e5f36185d53403ac9',
    chainId: 100,
    logoURI: '/icons/bcoin.webp',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
]

export const gnosisUtils = [
  {
    symbol: 'xDAI',
    name: 'Native Token',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    chainId: 100,
    logoURI: '/icons/weth.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'WXDAI',
    name: 'Wrapped DAI',
    address: '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d',
    chainId: 100,
    logoURI: '/icons/DAI.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'WETH',
    name: 'Wrapped ETH on xDAI',
    address: '0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1',
    chainId: 100,
    logoURI: '/icons/weth.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'WBTC',
    name: 'Wrapped BTC on xDAI',
    address: '0x8e5bBbb09Ed1ebdE8674Cda39A0c169401db4252',
    chainId: 100,
    logoURI: '/icons/btc.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'LINK',
    name: 'Chainlink',
    address: '0xE2e73A1c69ecF83F464EFCE6A5be353a37cA09b2',
    chainId: 100,
    logoURI: '/icons/LINK.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'GNO',
    name: 'Gnosis',
    address: '0x9C58BAcC331c9aa871AFD802DB6379a98e80CEdb',
    chainId: 100,
    logoURI: '/icons/gno.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
]
