export const usStables = [
  {
    symbol: 'USDC',
    name: 'Circle',
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    chainId: 1,
    logoURI: '/icons/USDC.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'USDT',
    name: 'Tether',
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    chainId: 1,
    logoURI: '/icons/USDT.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'USDS',
    name: 'USD Sky',
    address: '0xdc035d45d973e3ec169d2276ddab16f1e407384f',
    chainId: 1,
    logoURI: '/icons/eth/USDS-Sky.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'USDP',
    name: 'Paxos Dollar',
    address: '0x8e870d67f660d95d5be530380d0ec0bd388289e1',
    chainId: 1,
    logoURI: '/icons/eth/USDP-Paxos.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'USX',
    name: 'dForce USD',
    address: '0x0a5e677a6a24b2f1a2bf4f3bffc443231d2fdec8',
    chainId: 1,
    logoURI: '/icons/USX.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'FDUSD',
    name: 'FirstDigital Dollar',
    address: '0xc5f0f7b66764f6ec8c8dff7ba683102295e16409',
    chainId: 1,
    logoURI: '/icons/eth/FDUSD FirstDigital Dollar.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'PYUSD',
    name: 'PayPal USD',
    address: '0x6c3ea9036406852006290770bedfcaba0e23a0e8',
    chainId: 1,
    logoURI: '/icons/eth/PYUSD - PayPal.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'USD0',
    name: 'Usual',
    address: '0x73a15fed60bf67631dc6cd7bc5b6e8da8190acf5',
    chainId: 1,
    logoURI: '/icons/USD0.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'Dai',
    name: 'DAI',
    address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    chainId: 1,
    logoURI: '/icons/DAI.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'GHO',
    name: 'AAVE GHO',
    address: '0x40d16fc0246ad3160ccc09b8d0d3a2cd28ae6c2f',
    chainId: 1,
    logoURI: '/icons/eth/GHO AAVE.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'crvUSD',
    name: 'Curve Stablecoin',
    address: '0xf939e0a03fb07f59a73314e73794be0e57ac1b4e',
    chainId: 1,
    logoURI: '/icons/eth/crvUSD Curve.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'csUSDL',
    name: 'Coinshift Stablecoin',
    address: '0xbEeFc011e94f43b8B7b455eBaB290C7Ab4E216f1',
    chainId: 1,
    logoURI: '/icons/eth/csUSDL-badge.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'EURS',
    name: 'Stasis Euro',
    address: '0xdb25f211ab05b1c97d595516f45794528a807ad8',
    chainId: 1,
    logoURI: '/icons/eth/EURS Stasis EURO.jpeg',
    decimals: 2,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'AEUR',
    name: 'Anchored Coins AEUR',
    address: '0xA40640458FBc27b6EefEdeA1E9C9E17d4ceE7a21',
    chainId: 1,
    logoURI: '/icons/eth/AEUR Anchored Coins AEUR.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'EURI',
    name: 'Eurite',
    address: '0x9d1a7a3191102e9f900faa10540837ba84dcbae7',
    chainId: 1,
    logoURI: '/icons/eth/EURI - Eurite.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'EURT',
    name: 'Euro Tether',
    address: '0xc581b735a1688071a1746c968e0798d642ede491',
    chainId: 1,
    logoURI: '/icons/eth/EURT - Euro Tether.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'EUROC',
    name: 'Circle EURO',
    address: '0x1abaea1f7c830bd89acc67ec4af516284b1bc33c',
    chainId: 1,
    logoURI: '/icons/eth/EUROC Circle.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'VEUR',
    name: 'VNX Euro',
    address: '0x6ba75d640bebfe5da1197bb5a2aff3327789b5d3',
    chainId: 1,
    logoURI: '/icons/eth/VEUR - VNX Euro.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
]

export const euroStables = [
  {
    symbol: 'EURS',
    name: 'Stasis Euro',
    address: '0xdb25f211ab05b1c97d595516f45794528a807ad8',
    chainId: 1,
    logoURI: '/icons/eth/EURS Stasis EURO.jpeg',
    decimals: 2,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'AEUR',
    name: 'Anchored Coins AEUR',
    address: '0xA40640458FBc27b6EefEdeA1E9C9E17d4ceE7a21',
    chainId: 1,
    logoURI: '/icons/eth/AEUR Anchored Coins AEUR.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'EURI',
    name: 'Eurite',
    address: '0x9d1a7a3191102e9f900faa10540837ba84dcbae7',
    chainId: 1,
    logoURI: '/icons/eth/EURI - Eurite.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'EURT',
    name: 'Euro Tether',
    address: '0xc581b735a1688071a1746c968e0798d642ede491',
    chainId: 1,
    logoURI: '/icons/eth/EURT - Euro Tether.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'EUROC',
    name: 'Circle EURO',
    address: '0x1abaea1f7c830bd89acc67ec4af516284b1bc33c',
    chainId: 1,
    logoURI: '/icons/eth/EUROC Circle.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'VEUR',
    name: 'VNX Euro',
    address: '0x6ba75d640bebfe5da1197bb5a2aff3327789b5d3',
    chainId: 1,
    logoURI: '/icons/eth/VEUR - VNX Euro.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
]

export const jpyStables = []

export const ustBills = [
  {
    symbol: 'USDL',
    name: 'Lift Dollar',
    address: '0xbdc7c08592ee4aa51d06c27ee23d5087d65adbcd',
    chainId: 1,
    logoURI: '/img/explorer-logos/usdl.jpg',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'USDM',
    name: 'Mountain Protocol',
    address: '0xbbaec992fc2d637151daf40451f160bf85f3c8c1',
    chainId: 1,
    logoURI: '/icons/USDM.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'USDY',
    name: 'Ondo Finance',
    address: '0x96f6ef951840721adbf46ac996b59e0235cb985c',
    chainId: 1,
    logoURI: '/icons/USDY.svg',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
]

export const commodities = [
  {
    symbol: 'PAXG',
    name: 'Paxos Gold',
    address: '0x45804880de22913dafe09f4980848ece6ecbaf78',
    chainId: 1,
    logoURI: '/icons/eth/PAXG Paxos.png',
    decimals: 18,
    isNative: false,
    isToken: true,
  },
  {
    symbol: 'XAUt',
    name: 'Tether Gold',
    address: '0x68749665ff8d2d112fa859aa293f07a622782f38',
    chainId: 1,
    logoURI: '/icons/eth/XAUt Tether Gold.png',
    decimals: 6,
    isNative: false,
    isToken: true,
  },
]

export const ethUtilities = []
