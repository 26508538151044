export const stables = [
  {
    address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    chainId: 42161,
    logoURI: '/icons/USDC.png',
    decimals: 6,
    isNative: false,
    isToken: true,
    name: 'Circle',
    symbol: 'USDC',
  },
  {
    address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    chainId: 42161,
    logoURI: '/icons/USDT.png',
    decimals: 6,
    isNative: false,
    isToken: true,
    name: 'Tether',
    symbol: 'USDT',
  },
  {
    address: '0x641441c631e2F909700d2f41FD87F0aA6A6b4EDb',
    chainId: 42161,
    logoURI: '/icons/USX.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'dForce USD',
    symbol: 'USX',
  },
  {
    address: '0x680447595e8b7b3aa1b43beb9f6098c79ac2ab3f',
    chainId: 42161,
    logoURI: '/icons/USDD.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'USDD',
    symbol: 'USDD',
  },
  {
    address: '0x35f1c5cb7fb977e669fd244c567da99d8a3a6850',
    chainId: 42161,
    logoURI: '/icons/USD0.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Usual',
    symbol: 'USD0',
  },
  {
    address: '0x498Bf2B1e120FeD3ad3D42EA2165E9b73f99C1e5',
    chainId: 42161,
    logoURI: '/icons/crvUSD.webp',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Curve Stablecoin',
    symbol: 'crvUSD',
  },
  {
    address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    chainId: 42161,
    logoURI: '/icons/DAI.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Dai',
    symbol: 'DAI',
  },
  {
    address: '0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65',
    chainId: 42161,
    logoURI: '/icons/USD+.png',
    decimals: 6,
    isNative: false,
    isToken: true,
    name: 'Overnight USD',
    symbol: 'USD+',
  },
]

export const tbills = [
  {
    address: '0x7751e2f4b8ae93ef6b79d86419d42fe3295a4559',
    chainId: 42161,
    logoURI: '/icons/wusdl.jpg',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'WUSDL',
    symbol: 'wUSDL',
  },
  {
    address: '0x59D9356E565Ab3A36dD77763Fc0d87fEaf85508C',
    chainId: 42161,
    logoURI: '/icons/USDM.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Mountain Protocol',
    symbol: 'USDM',
  },
  {
    address: '0x35e050d3c0ec2d29d269a8ecea763a183bdf9a9d',
    chainId: 42161,
    logoURI: '/icons/USDY.svg',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Ondo Finance',
    symbol: 'USDY',
  },
]

export const utilities = [
  {
    address: '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
    chainId: 42161,
    logoURI: '/icons/LINK.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Chainlink',
    symbol: 'LINK',
  },
  {
    address: '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0',
    chainId: 42161,
    logoURI: '/icons/UNI.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Uniswap',
    symbol: 'Uni',
  },
  {
    address: '0x912ce59144191c1204e64559fe8253a0e49e6548',
    chainId: 42161,
    logoURI: '/icons/ARB.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Arbitrum',
    symbol: 'ARB',
  },
  {
    address: '0x9623063377ad1b27544c965ccd7342f7ea7e88c7',
    chainId: 42161,
    logoURI: '/icons/GRT.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'The Graph',
    symbol: 'GRT',
  },
  {
    address: '0x13ad51ed4f1b7e9dc168d8a00cb3f4ddd85efa60',
    chainId: 42161,
    logoURI: '/icons/LDO.png',
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Lido DAO',
    symbol: 'LDO',
  },
]

export const stocks = [
  {
    address: '0x43333771E8d5FD74E5491d10aeAc2BFdC23D19C8',
    chainId: 42161,
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Dinari - Wrapped GameStop Corp. Class A',
    symbol: 'GME.dw',
  },
  {
    address: '0x407274ABb9241Da0A1889c1b8Ec65359dd9d316d',
    chainId: 42161,
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Dinari - Wrapped Coinbase Global, Inc. Class A Common Stock',
    symbol: 'wCOIN.d',
  },
  {
    address: '0x2F71B3804a74A013d00065225752dBA9aD061e51',
    chainId: 42161,
    decimals: 18,
    isNative: false,
    isToken: true,
    name: 'Dinari - Wrapped Reddit, Inc.',
    symbol: 'RDDT.dw',
  },
]
