import { FC } from 'react'

interface PaginationProps {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}

const Pagination: FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="flex space-x-2">
      <button
        className="size-12 rounded-full text-white font-medium bg-[#FFFFFF0A] hover:bg-[#FFFFFF14] flex justify-center items-center pr-0.5"
        onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.542 16.5999L7.10866 11.1666C6.46699 10.5249 6.46699 9.4749 7.10866 8.83324L12.542 3.3999"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index}
          className={`h-12 rounded-full text-white font-medium duration-200 ${
            index + 1 === currentPage ? 'bg-[#FFFFFF24] w-16' : 'bg-[#FFFFFF0A] hover:bg-[#FFFFFF14] w-12'
          }`}
          onClick={() => onPageChange(index + 1)}
        >
          {index + 1}
        </button>
      ))}
      <button
        className="size-12 rounded-full text-white font-medium bg-[#FFFFFF0A] hover:bg-[#FFFFFF14] flex justify-center items-center pl-0.5"
        onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.45801 3.4001L12.8913 8.83343C13.533 9.4751 13.533 10.5251 12.8913 11.1668L7.45801 16.6001"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  )
}

export default Pagination
