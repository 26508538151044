import analyticsComingSoonIMG from 'assets/images/analytics-coming-soon.png'
import clsx from 'clsx'
import { LoadingSparkle } from 'nft/components/common/Loading/LoadingSparkle'
import { cloneElement, FC, useEffect, useState } from 'react'
import useFetchAndDecrypt from 'utils/useFetchAndDecrypt'

import { Metric, MetricsResponseType } from '../ExplorerTypes'
import { AnalyticsWrapper, TopGradientBordered } from '../Styleds'

interface MetricsData {
  marketCap?: Metric
  monthlyTransferVolume?: Metric
  holders?: Metric
}

const StableCoinsAnalytics: FC = () => {
  const [data, setAnalyticsData] = useState<MetricsData | null>(null)

  const { data: apiData, loading } = useFetchAndDecrypt<MetricsResponseType>(
    `${process.env.REACT_APP_PROXY_SERVER_URL}/metrics/merics_by_class_name/stablecoins`
  )

  useEffect(() => {
    if (apiData) {
      setAnalyticsData(
        apiData.metrics.reduce(
          (acc, metric) => {
            switch (metric.label) {
              case 'Market Cap':
                acc.marketCap = metric
                break
              case 'Monthly Transfer Volume':
                acc.monthlyTransferVolume = metric
                break
              case 'Holders':
                acc.holders = metric
                break
              default:
                break
            }

            return acc
          },
          {
            marketCap: undefined,
            monthlyTransferVolume: undefined,
            holders: undefined,
          } as MetricsData
        )
      )
    }
  }, [apiData])

  return (
    <div className="w-full max-w-[1560px]" id="analytics" style={{ scrollMarginTop: '72px' }}>
      {loading ? (
        <div className="w-full h-80 flex justify-center items-center">
          <LoadingSparkle />
        </div>
      ) : (
        <>
          <div className="flex space-x-4">
            <AnalyticsWrapper className="max-lg:!hidden">
              <div className="flex flex-col space-y-4 shrink-0">
                <div className="flex flex-col">
                  <p className="opacity-50">Market Cap</p>
                  <p className="text-[32px]">
                    <span className="font-semibold">{data?.marketCap ? data.marketCap.value : '--'}</span>
                  </p>
                  <p
                    className={clsx(
                      data?.marketCap ? (data?.marketCap?.change.includes('+') ? 'text-green-400' : 'text-red-400') : ''
                    )}
                  >
                    {data?.marketCap?.change}{' '}
                    <span className="opacity-50 text-white text-xs ml-2">{data?.marketCap?.period}</span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="opacity-50">Monthly Transfer Volume</p>
                  <p className="text-[32px]">
                    <span className="font-semibold">
                      {data?.monthlyTransferVolume ? data.monthlyTransferVolume.value : '--'}
                    </span>
                  </p>
                  <p
                    className={clsx(
                      data?.monthlyTransferVolume
                        ? data?.monthlyTransferVolume?.change.includes('+')
                          ? 'text-green-400'
                          : 'text-red-400'
                        : ''
                    )}
                  >
                    {data?.monthlyTransferVolume?.change}
                    <span className="opacity-50 text-white text-xs ml-2">{data?.monthlyTransferVolume?.period}</span>
                  </p>
                </div>
              </div>
              <div className="relative w-full">
                <img src={analyticsComingSoonIMG} alt="analytics" className="blur-md" />
                <div className="absolute top-0 left-0 right-0 bottom-0 backdrop-blur-3xl z-10 rounded-3xl flex justify-center items-center text-xl font-medium">
                  Explorer Analytics Coming Soon
                </div>
              </div>
            </AnalyticsWrapper>
            <TopGradientBordered className="rounded-[28px] flex flex-col max-lg:hidden shrink-0">
              <div className="max-lg:hidden w-full aspect-square flex justify-center items-center bg-[#FFFFFF05] rounded-xl mr-4">
                {cloneElement(holdersIcon || <div />, {
                  className: 'w-8 h-8 opacity-50',
                })}
              </div>
              <div className="flex flex-col mt-2">
                <p className="opacity-50 text-nowrap">Holders</p>
                <p className="text-[32px]">
                  <span className="font-semibold text-nowrap">{data?.holders ? data.holders?.value : '--'}</span>
                </p>
                <p
                  className={clsx(
                    data?.holders ? (data?.holders?.change.includes('+') ? 'text-green-400' : 'text-red-400') : ''
                  )}
                >
                  {data?.holders?.change}
                  <span className="opacity-50 text-white text-xs ml-2">{data?.holders?.period}</span>
                </p>
              </div>
            </TopGradientBordered>
          </div>
          <div className="w-full flex max-lg:flex-col lg:space-x-4 max-lg:space-y-4 mb-10">
            {[data?.marketCap, data?.monthlyTransferVolume, data?.holders].map((item) => (
              <TopGradientBordered key={item?._id} className="rounded-[28px] w-full flex lg:hidden">
                <div className="max-lg:hidden h-full aspect-square flex justify-center items-center bg-[#FFFFFF05] rounded-xl mr-4">
                  {cloneElement(item?.label === 'Holders' ? holdersIcon : <div />, {
                    className: 'w-[40px] h-[40px] opacity-50',
                  })}
                </div>
                <div className="flex flex-col">
                  <p className="opacity-50">{item?.label}</p>
                  <p className="text-[32px]">
                    <span className="font-semibold">{item?.value || '--'}</span>
                  </p>
                  <p className={clsx(item ? (item?.change.includes('+') ? 'text-green-400' : 'text-red-400') : '')}>
                    {item?.change}
                  </p>
                </div>
              </TopGradientBordered>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default StableCoinsAnalytics

const holdersIcon = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.2167 12.9499C29.1001 12.9333 28.9834 12.9333 28.8667 12.9499C26.2834 12.8666 24.2334 10.7499 24.2334 8.14992C24.2334 5.49992 26.3834 3.33325 29.0501 3.33325C31.7001 3.33325 33.8667 5.48325 33.8667 8.14992C33.8501 10.7499 31.8001 12.8666 29.2167 12.9499Z"
      fill="white"
    />
    <path
      d="M34.6493 24.4999C32.7827 25.7499 30.166 26.2166 27.7493 25.8999C28.3827 24.5333 28.716 23.0166 28.7327 21.4166C28.7327 19.7499 28.366 18.1666 27.666 16.7833C30.1327 16.4499 32.7493 16.9166 34.6327 18.1666C37.266 19.8999 37.266 22.7499 34.6493 24.4999Z"
      fill="white"
    />
    <path
      d="M10.7339 12.9499C10.8506 12.9333 10.9673 12.9333 11.0839 12.9499C13.6673 12.8666 15.7173 10.7499 15.7173 8.14992C15.7173 5.48325 13.5673 3.33325 10.9006 3.33325C8.25059 3.33325 6.10059 5.48325 6.10059 8.14992C6.10059 10.7499 8.15059 12.8666 10.7339 12.9499Z"
      fill="white"
    />
    <path
      d="M10.9169 21.4169C10.9169 23.0336 11.2669 24.5669 11.9002 25.9503C9.5502 26.2003 7.1002 25.7003 5.3002 24.5169C2.66686 22.7669 2.66686 19.9169 5.3002 18.1669C7.08353 16.9669 9.6002 16.4836 11.9669 16.7503C11.2835 18.1503 10.9169 19.7336 10.9169 21.4169Z"
      fill="white"
    />
    <path
      d="M20.2 26.45C20.0667 26.4333 19.9167 26.4333 19.7667 26.45C16.7 26.35 14.25 23.8333 14.25 20.7333C14.2667 17.5667 16.8167 15 20 15C23.1667 15 25.7333 17.5667 25.7333 20.7333C25.7167 23.8333 23.2833 26.35 20.2 26.45Z"
      fill="white"
    />
    <path
      d="M14.783 29.8999C12.2663 31.5832 12.2663 34.3499 14.783 36.0166C17.6497 37.9332 22.3497 37.9332 25.2163 36.0166C27.733 34.3332 27.733 31.5666 25.2163 29.8999C22.3663 27.9832 17.6663 27.9832 14.783 29.8999Z"
      fill="white"
    />
  </svg>
)
