import styled from 'styled-components/macro'

export const TableWrapper = styled.div`
  width: 100%;
  max-width: 1560px;
  height: 100%;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(66px);
  border-radius: 28px;
  padding: 20px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 28px 28px 0 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 0;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0));
    mask-size: 100% 50%;
    mask-repeat: no-repeat;
    z-index: -1;
  }
`

export const TopGradientBordered = styled.div`
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(66px);
  border-radius: 28px;
  padding: 20px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 28px 28px 0 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 0;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0));
    mask-size: 100% 50%;
    mask-repeat: no-repeat;
    z-index: -1;
  }
`

export const AnalyticsWrapper = styled.div`
  width: 100%;
  max-width: 1560px;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(66px);
  border-radius: 28px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 32px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 28px 28px 0 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 0;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0));
    mask-size: 100% 50%;
    mask-repeat: no-repeat;
    z-index: -1;
  }
`
