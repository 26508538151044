import { Currency } from '@uniswap/sdk-core'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { AutoRow } from 'components/Row'
import { COMMON_BASES } from 'constants/routing'
import { useTokenInfoFromActiveList } from 'hooks/useTokenInfoFromActiveList'
import { getTokenAddress } from 'lib/utils/analytics'
import { useEffect, useState } from 'react'
import { Text } from 'rebass'
import styled from 'styled-components/macro'
import { currencyId } from 'utils/currencyId'

import { baseUsStables, baseUstBills, baseUtils } from './baseTokens'
import CurrencyList from './CurrencyList'
import { commodities, usStables, ustBills } from './ethTokens'
import { gnosisStocks, gnosisUsStables, gnosisUtils } from './gnosistokens'
import { polyUsStables, polyUstBills, polyUtils } from './polytokens'
import { stables, stocks, tbills, utilities } from './tokens'

const MobileWrapper = styled(AutoColumn)`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
  `};
`

const BaseWrapper = styled.div<{ disable?: boolean }>`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 72px;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(23.999998092651367px);
`

const formatAnalyticsEventProperties = (currency: Currency, searchQuery: string, isAddressSearch: string | false) => ({
  token_symbol: currency?.symbol,
  token_chain_id: currency?.chainId,
  token_address: getTokenAddress(currency),
  is_suggested_token: true,
  is_selected_from_list: false,
  is_imported_by_user: false,
  ...(isAddressSearch === false
    ? { search_token_symbol_input: searchQuery }
    : { search_token_address_input: isAddressSearch }),
})

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
  searchQuery,
  isAddressSearch,
}: {
  chainId?: number
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
  searchQuery: string
  isAddressSearch: string | false
}) {
  const bases = typeof chainId !== 'undefined' ? COMMON_BASES[chainId] ?? [] : []

  useEffect(() => {
    console.log('chainId', chainId)
  }, [chainId])

  const [activeTab, setActiveTab] = useState(1)
  const activeList =
    chainId === 42161
      ? activeTab === 1
        ? stables
        : activeTab === 2
        ? tbills
        : activeTab === 3
        ? utilities
        : stocks
      : chainId === 8453
      ? activeTab === 1
        ? baseUsStables
        : activeTab === 2
        ? baseUstBills
        : baseUtils
      : chainId === 137
      ? activeTab === 1
        ? polyUsStables
        : activeTab === 2
        ? polyUstBills
        : polyUtils
      : chainId === 100
      ? activeTab === 1
        ? gnosisUsStables
        : activeTab === 2
        ? gnosisStocks
        : gnosisUtils
      : activeTab === 1
      ? usStables
      : activeTab === 2
      ? ustBills
      : commodities

  return (
    <>
      <MobileWrapper gap="md">
        <div>
          {chainId === 42161 ? (
            <>
              <div className="mt-[20px] mb-[10px]">
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 1 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(1)}
                >
                  Stable
                </span>
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 2 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(2)}
                >
                  U.S. T-Bills
                </span>
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 3 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(3)}
                >
                  Utility
                </span>
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 4 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(4)}
                >
                  Stocks
                </span>
              </div>
              {activeTab === 1 && (
                <AutoRow gap="4px">
                  {stables.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
              {activeTab === 2 && (
                <AutoRow gap="4px">
                  {tbills.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
              {activeTab === 3 && (
                <AutoRow gap="4px">
                  {utilities.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
              {activeTab === 4 && (
                <AutoRow gap="4px">
                  {stocks.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
            </>
          ) : chainId === 8453 ? (
            <>
              <div className="mt-[20px] mb-[10px]">
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 1 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(1)}
                >
                  Stables
                </span>
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 2 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(2)}
                >
                  U.S. T-Bills
                </span>
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 3 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(3)}
                >
                  Utilities
                </span>
              </div>
              {activeTab === 1 && (
                <AutoRow gap="4px">
                  {baseUsStables.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
              {activeTab === 2 && (
                <AutoRow gap="4px">
                  {baseUstBills.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
              {activeTab === 3 && (
                <AutoRow gap="4px">
                  {baseUtils.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
            </>
          ) : chainId === 137 ? (
            <>
              <div className="mt-[20px] mb-[10px]">
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 1 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(1)}
                >
                  Stables
                </span>
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 2 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(2)}
                >
                  U.S. T-Bills
                </span>
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 3 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(3)}
                >
                  Utilities
                </span>
              </div>
              {activeTab === 1 && (
                <AutoRow gap="4px">
                  {polyUsStables.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
              {activeTab === 2 && (
                <AutoRow gap="4px">
                  {polyUstBills.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
              {activeTab === 3 && (
                <AutoRow gap="4px">
                  {baseUtils.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
            </>
          ) : chainId === 100 ? (
            <>
              <div className="mt-[20px] mb-[10px]">
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 1 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(1)}
                >
                  Stables
                </span>
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 2 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(2)}
                >
                  Stocks
                </span>
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 3 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(3)}
                >
                  Utilities
                </span>
              </div>
              {activeTab === 1 && (
                <AutoRow gap="4px">
                  {gnosisUsStables.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
              {activeTab === 2 && (
                <AutoRow gap="4px">
                  {gnosisStocks.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
              {activeTab === 3 && (
                <AutoRow gap="4px">
                  {gnosisUtils.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
            </>
          ) : (
            <>
              <div className="mt-[20px] mb-[10px]">
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 1 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(1)}
                >
                  Stables
                </span>
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 2 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(2)}
                >
                  U.S. T-Bills
                </span>
                <span
                  className={`text-[13px] text-white bg-[rgba(255,255,255,0.08)] rounded-[60px] px-[16px] py-[10px] inline-block mr-[10px] mb-[10px] cursor-pointer ${
                    activeTab !== 3 && 'opacity-50'
                  }`}
                  onClick={() => setActiveTab(3)}
                >
                  Commodities
                </span>
              </div>
              {activeTab === 1 && (
                <AutoRow gap="4px">
                  {usStables.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
              {activeTab === 2 && (
                <AutoRow gap="4px">
                  {ustBills.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
              {activeTab === 3 && (
                <AutoRow gap="4px">
                  {commodities.map((currency: Currency) => {
                    const isSelected = selectedCurrency?.name === currency.name

                    return (
                      <BaseWrapper
                        tabIndex={0}
                        onKeyPress={(e) => !isSelected && e.key === 'Enter' && onSelect(currency)}
                        onClick={() => !isSelected && onSelect(currency)}
                        disable={isSelected}
                        key={currencyId(currency)}
                      >
                        <CurrencyLogoFromList currency={currency} />
                        <Text fontWeight={500} fontSize={16}>
                          {currency.symbol}
                        </Text>
                      </BaseWrapper>
                    )
                  })}
                </AutoRow>
              )}
            </>
          )}
        </div>
      </MobileWrapper>
      <CurrencyList height={0} currencies={activeList} onCurrencySelect={onSelect} />
    </>
  )
}

/** helper component to retrieve a base currency from the active token lists */
function CurrencyLogoFromList({ currency }: { currency: Currency }) {
  const token = useTokenInfoFromActiveList(currency)

  return <CurrencyLogo currency={token} style={{ marginRight: 8 }} />
}
