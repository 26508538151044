import clsx from 'clsx'
import Pagination from 'components/Pagination/Pagination'
import { LoadingSparkle } from 'nft/components/common/Loading/LoadingSparkle'
import { FC, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link, useLocation } from 'react-router-dom'
import { normalizeNumber } from 'utils/normalizeNumber'
import useFetchAndDecrypt from 'utils/useFetchAndDecrypt'

import ExplorerTable, { ColumnsDataType } from '../ExplorerTable/ExplorerTable'
import { Asset, AssetsResponseType } from '../ExplorerTypes'
import { TableWrapper } from '../Styleds'
import StableCoinsAnalytics from './StableCoinsAnalytics'

const StableCoinsPage: FC = () => {
  const [tableData, setTableData] = useState<Asset[]>([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const { loading, error, data } = useFetchAndDecrypt<AssetsResponseType>(
    `${process.env.REACT_APP_PROXY_SERVER_URL}/assets/asset_by_class_name/stablecoins?page=${page}&limit=10`
  )

  useEffect(() => {
    if (data) {
      setTableData(data.assets)
      setTotalPages(data.totalPages)
    }
  }, [data])

  const location = useLocation()

  useEffect(() => {
    const hash = location.hash
    if (hash) {
      const element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [location.hash])

  const headerPortalContainer = document.getElementById('header-portal')

  return (
    <section className="min-h-screen h-full relative w-full flex flex-col justify-center items-center">
      {headerPortalContainer &&
        ReactDOM.createPortal(
          <div className="flex justify-between items-center h-full px-6 space-x-12">
            <h1 className="text-xl font-semibold text-white flex-shrink-0">Stablecoin</h1>
            <div className="flex items-center space-x-5 flex-grow justify-end max-sm:hidden">
              <Link to="#analytics" className="text-white duration-200 flex-shrink-0 opacity-50 hover:opacity-100">
                Analytics
              </Link>
              <div className="h-5 w-0.5 rounded-full bg-white opacity-5"></div>
              <Link to="#top-tokens" className="text-white duration-200 flex-shrink-0 opacity-50 hover:opacity-100">
                Top Stablecoin
              </Link>
              <div className="h-5 w-0.5 rounded-full bg-white opacity-5"></div>
              {/* <Link to="#top-pairs" className="text-white duration-200 flex-shrink-0 opacity-50 hover:opacity-100">
                Top Pairs
              </Link> */}
              <p className="flex-shrink-0 max-lg:hidden">
                <span className="opacity-50">Top Pairs</span>{' '}
                <span className="bg-[#FFFFFF0A] ml-2 px-2 py-1 rounded-full rounded-es-none">Soon</span>
              </p>
              <div className="h-5 w-0.5 rounded-full bg-white opacity-5 max-lg:hidden"></div>
              <p className="flex-shrink-0 max-lg:hidden">
                <span className="opacity-50">Transactions</span>{' '}
                <span className="bg-[#FFFFFF0A] ml-2 px-2 py-1 rounded-full rounded-es-none">Soon</span>
              </p>
            </div>
          </div>,
          headerPortalContainer
        )}
      <StableCoinsAnalytics />

      <TableWrapper id="top-tokens" style={{ scrollMarginTop: '72px' }}>
        {error ? (
          <div className="w-full h-full flex-grow flex flex-col justify-center items-center mt-10">
            <div className="">{error}</div>
          </div>
        ) : (
          <>
            {loading ? (
              <div className="w-full h-full flex-grow flex flex-col justify-center items-center mt-10">
                <LoadingSparkle />
              </div>
            ) : (
              <ExplorerTable columnsData={columnsData} data={tableData} details={<StablecoinAssetDetails />} />
            )}
            <div className="flex justify-center">
              <Pagination currentPage={page} totalPages={totalPages} onPageChange={(page) => setPage(page)} />
            </div>
          </>
        )}
      </TableWrapper>
    </section>
  )
}

export default StableCoinsPage

const columnsData: ColumnsDataType<Asset>[] = [
  {
    name: '#',
    key: 'logo',
    render: (data) => (
      <div className="w-7 h-7">
        {data.icon_url ? (
          <img src={data.icon_url} alt={data.ticker} className="w-7 h-7 rounded-full bg-white" width={28} height={28} />
        ) : (
          <p className="text-sm font-medium opacity-50">--</p>
        )}
      </div>
    ),
  },
  {
    name: 'Name',
    key: 'name',
    render(data) {
      return (
        <div className="flex flex-col space-y-1">
          <p className="font-medium text-sm">{data.ticker}</p>
          <p className="font-medium text-sm opacity-50">{data.name}</p>
        </div>
      )
    },
  },
  {
    name: 'Networks',
    key: 'networks',
    render(data) {
      if (!data.tokens || data.tokens.length <= 0) return <p className="text-sm font-medium opacity-50">--------</p>
      const networks = data.tokens.reduce<{ name: string; icon_url: string }[]>((acc, token) => {
        if (!token.network?.name) return acc
        if (!acc.find((item) => item.name === token.network?.name)) {
          acc.push({ name: token.network.name, icon_url: token.network.icon_url })
        }
        return acc
      }, [])
      return networks.length > 0 ? (
        <div className="flex relative">
          {networks?.map((token, index) => (
            <span
              key={token.name}
              className={clsx('relative w-8 h-8 group', {
                '-ml-3': index !== 0,
              })}
            >
              <img
                src={
                  token.icon_url
                    ? token.icon_url.startsWith('https')
                      ? token.icon_url
                      : process.env.REACT_APP_S3_ADDRESS + token.icon_url
                    : undefined
                }
                alt={token.name}
                width={32}
                height={32}
                className="absolute top-0 left-0 rounded-full border-2 border-[#121212] w-8 h-8 bg-[#414141] group-hover:z-[100]"
              />
              <span className="absolute opacity-0 z-0 top-0 left-1/2 -translate-x-1/2 group-hover:opacity-100 group-hover:-top-5 duration-200 text-xs text-nowrap">
                {token.name}
              </span>
            </span>
          ))}
        </div>
      ) : (
        <p className="text-sm font-medium opacity-50">--------</p>
      )
    },
  },
  {
    name: 'Market Cap',
    key: 'marketcap',
    render: (data) => (
      <p
        className={clsx('text-sm font-medium', {
          'opacity-50': !data?.bridged_token_market_cap_dollar?.val,
        })}
      >
        {data?.bridged_token_market_cap_dollar?.val
          ? '$' + normalizeNumber(data.bridged_token_market_cap_dollar.val)
          : '--------'}
      </p>
    ),
  },
  {
    name: 'Price',
    key: 'price',
    render: (data) => (
      <p className="text-sm font-medium">
        {'$' +
          data.price_dollar?.val.toLocaleString(undefined, {
            maximumFractionDigits: 6,
          })}
      </p>
    ),
  },
  {
    name: 'Vol. 24h',
    key: 'volume',
    // render: () => <span className="opacity-50">--</span>,
    render: (data) => (
      <p className="text-sm font-medium">{'$' + normalizeNumber(Number(data?.trailing_30_day_transfer_volume?.val))}</p>
    ),
  },
  {
    name: 'Domicile',
    key: 'domicile',
    render: (data) =>
      data.issuer?.legal_structure_country ? (
        <div className="flex space-x-4 items-center">
          {data.issuer?.legal_structure_country_icon_url && (
            <img
              className="w-6 h-4"
              src={data.issuer?.legal_structure_country_icon_url}
              alt={data.issuer?.legal_structure_country}
            />
          )}
          <p
            className={clsx('text-sm font-medium', {
              'opacity-50': !data.issuer?.legal_structure_country,
            })}
          >
            {data.issuer?.legal_structure_country}
          </p>
        </div>
      ) : (
        <p className="text-sm font-medium opacity-50">--------</p>
      ),
  },
  {
    name: 'Rating',
    key: 'rating',
    render: (data) =>
      data.ratings && data.ratings?.length > 0 ? (
        <div className="flex space-x-4 w-max">
          {data.ratings?.map((rating) => (
            <a
              className="text-sm font-medium p-2 px-3 rounded-full bg-[#FFFFFF14] flex items-center space-x-2"
              key={rating.provider.id}
              href={rating.url}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="w-4 h-4 rounded-full bg-white overflow-hidden">
                <img
                  src={rating.provider.id === 44 ? spRatingImageUrl : rating.provider.icon_url}
                  alt={rating.provider.name}
                />
              </div>
              <span className="text-white">{rating.rating}</span>
            </a>
          ))}
        </div>
      ) : (
        <span className="opacity-50">--------</span>
      ),
  },
  // {
  //   name: '',
  //   key: 'operations',
  //   render: (data) => (
  //     <div className="flex space-x-2 justify-end max-sm:w-full">
  //       {data?.swapStatus ? (
  //         <button
  //           className={clsx(
  //             'text-sm font-medium p-2 px-3 rounded-full flex items-center justify-center space-x-2 max-sm:w-full',
  //             {
  //               'bg-[#FFFFFF] text-black': data.swapStatus.toLocaleLowerCase() === 'swap',
  //               'bg-[#FFFFFF15] text-white': data.swapStatus.toLocaleLowerCase() === 'soon',
  //             }
  //           )}
  //           disabled={data.swapStatus.toLocaleLowerCase() !== 'swap'}
  //           onClick={() => swap(data.ticker)}
  //         >
  //           <span>{data.swapStatus}</span>
  //         </button>
  //       ) : (
  //         <span className="opacity-50">--</span>
  //       )}
  //     </div>
  //   ),
  // },
]

const spRatingImageUrl =
  'https://driftt.imgix.net/https%3A%2F%2Fdriftt.imgix.net%2Fhttps%253A%252F%252Fs3.us-east-1.amazonaws.com%252Fcustomer-api-avatars-prod%252F1847433%252F7d3c19611ebd4b751d16f8944b7a5c0er73hk7gnt6er%3Ffit%3Dmax%26fm%3Dpng%26h%3D200%26w%3D200%26s%3Dce4b0080d15ab1ffd5dfbbbf4590c751?fit=max&fm=png&h=200&w=200&s=8d31a8643873d5b2750ed42cc5915489'

interface StablecoinAssetDetailsProps {
  data?: Asset
}

const StablecoinAssetDetails: FC<StablecoinAssetDetailsProps> = ({ data }) => {
  return (
    <div className="w-full bg=[#FFFFFF05] flex flex-col space-y-4">
      <div className="w-full flex">
        <p className="w-48 opacity-50">Top Pair:</p>
        <p className="opacity-50">Coming Soon</p>
      </div>
      <div className="w-full flex">
        <p className="w-48 opacity-50">Top Pair TVL:</p>
        <p className="opacity-50">Coming Soon</p>
      </div>
      <div className="w-full flex">
        <p className="w-48 opacity-50">Bankruptcy Remote:</p>
        <p>{data?.are_holdings_bankruptcy_remote ? 'Yes' : 'No'}</p>
      </div>
      <div className="w-full flex">
        <p className="w-48 opacity-50">Auditor:</p>
        <p>{data?.auditor?.name}</p>
      </div>
    </div>
  )
}
