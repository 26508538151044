import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { menuItems, MenuItemType } from 'utils/menuItems'

import MenuItem from './MenuItem'

const MainLayout: FC = () => {
  const [isMinified, setIsMinified] = useState(false)
  const [isClosed, setIsClosed] = useState(false)
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const [isInMobile, setIsInMobile] = useState(() => window.innerWidth < 640)

  const location = useLocation()

  const onSideBarEnter = () => {
    setIsClosed(false)
  }

  const onSideBarLeave = () => {
    setIsClosed(true)
  }

  const allSubItems = useMemo(
    () =>
      menuItems.reduce<MenuItemType[]>((acc, item) => {
        return acc.concat(item.children)
      }, []),
    []
  )

  useEffect(() => {
    const activeItem = allSubItems.find((item) => item.path === location.pathname)
    if (!isInMobile && activeItem && activeItem.isMinifiedSidebar) {
      setIsMinified(true)
      setIsClosed(true)
    } else {
      setIsMinified(false)
    }
  }, [allSubItems, location.pathname, isInMobile])

  useEffect(() => {
    const handleResize = () => {
      setIsInMobile(window.innerWidth < 640)
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const onItemClicked = useCallback(() => {
    setIsMobileOpen(false)
  }, [])

  return (
    <div className="flex h-screen w-full">
      <div
        className={clsx('bg-black/50 fixed top-0 bottom-0 left-0 right-0 -z-10', {
          'z-20': isMobileOpen,
        })}
        onClick={() => setIsMobileOpen(false)}
      ></div>
      <aside
        className={clsx(
          'flex-shrink-0 z-20 h-dvh overflow-y-auto overflow-x-hidden newera-scrollbar fixed sm:relative duration-200',
          {
            'w-64': !isMinified,
            'w-16': isMinified,
            '-left-64 sm:left-0': !isMobileOpen,
            'left-0': isMobileOpen,
          }
        )}
      >
        <nav
          className={clsx(
            'p-1 relative h-max min-h-dvh top-0 z-30 overflow-hidden bg-[#FFFFFF0A] duration-200 backdrop-blur-2xl flex flex-col',
            {
              'w-64': !isClosed,
              'w-16': isClosed,
              'hover:fixed': isMinified && !isInMobile,
            }
          )}
          onMouseEnter={!isInMobile && isMinified ? onSideBarEnter : undefined}
          onMouseLeave={!isInMobile && isMinified ? onSideBarLeave : undefined}
        >
          <div
            className="absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2 size-20 rounded-full rotate-180 blur-2xl"
            style={{
              background:
                'conic-gradient(from -39.44deg at 106.91% 56.12%, #FF1A41 -26.55deg, #FFFFFF 15.61deg, #FFFFFF 117.24deg, #FF2748 130.57deg, #6200FF 281.81deg, #FF1A41 333.45deg, #FFFFFF 375.61deg)',
            }}
          ></div>
          {!isClosed && (
            <>
              <div
                className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 size-[154px] rounded-full blur-[104px]"
                style={{
                  background:
                    'conic-gradient(from 92.32deg at -30.55% 36.97%, #FF1A41 -82.09deg, #FF264B 17.02deg, #FFFFFF 84.5deg, #FFFFFF 117.24deg, #FF2748 154.31deg, #6200FF 238.49deg, #FF1A41 277.91deg, #FF264B 377.02deg)',
                }}
              ></div>
              <div
                className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 size-[154px] rounded-full"
                style={{
                  background:
                    'conic-gradient(from 92.32deg at -30.55% 36.97%, #FF1A41 -82.09deg, #FF264B 17.02deg, #FFFFFF 84.5deg, #FFFFFF 117.24deg, #FF2748 154.31deg, #6200FF 238.49deg, #FF1A41 277.91deg, #FF264B 377.02deg)',
                }}
              ></div>
            </>
          )}
          <div className="flex space-x-2 items-center">
            <img
              src="/img/logo2.png"
              alt="Logo"
              className={isClosed ? 'duration-200 size-12' : 'duration-200 size-16'}
            />
            {!isClosed && (
              <h1 className="text-nowrap">
                <span className="font-extrabold">NewEra</span> Finance
              </h1>
            )}
          </div>
          {menuItems.map((item) => (
            <MenuItem key={item.categoryId} categoryItem={item} isClosed={isClosed} onItemClicked={onItemClicked} />
          ))}
          {!isClosed && (
            <div className="flex flex-col space-y-2 px-4 mb-28 mt-4 flex-grow justify-end">
              <p className="font-extrabold text-[28px]">+250K</p>
              <p className="opacity-50 text-xs">Join Our Community</p>
            </div>
          )}
        </nav>
      </aside>

      <div className="flex-1 flex flex-col overflow-y-auto newera-scrollbar">
        <header
          className={clsx(
            `sticky h-16 w-[calc(100vw-${
              isMinified ? '64' : '256'
            }px)] top-0 bg-[#FFFFFF0A] backdrop-blur-xl z-10 shrink-0 flex items-center`
          )}
        >
          <button
            className="sm:hidden size-12 ml-4 text-3xl text-center mb-2"
            onClick={() => setIsMobileOpen((prev) => !prev)}
          >
            ☰
          </button>
          <div className="flex-grow h-full" id="header-portal"></div>
        </header>
        <main className={clsx(`flex-1 p-4 w-[calc(100vw-${isMinified ? '64' : '256'}px)] shrink-0`)}>
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default MainLayout
