import CryptoJS from 'crypto-js'
import { useCallback, useEffect, useState } from 'react'

const SECRET_KEY = process.env.REACT_APP_PROXY_SERVER_SECRET_KEY
// const shouldDecrypt = process.env.NODE_ENV === 'production' && window.location.hostname === 'www.newera.finance'
const shouldDecrypt = false

const useFetchAndDecrypt = <T>(url: string) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<T | undefined>(undefined)

  const fetchAndDecrypt = useCallback(async () => {
    try {
      setLoading(true)
      setError(null)

      // Fetch data from the API
      const response = await fetch(url)

      if (!response.ok) {
        throw new Error('Failed to fetch data')
      }

      let parsedData

      if (shouldDecrypt && SECRET_KEY) {
        // Get the encrypted response as text
        const encryptedResponse = await response.text()

        // Decrypt the response
        const bytes = CryptoJS.AES.decrypt(encryptedResponse, SECRET_KEY)
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8)

        try {
          parsedData = JSON.parse(JSON.parse(decryptedData))
        } catch (error) {
          parsedData = decryptedData // If it's not JSON, use the raw decrypted string
        }
      } else {
        parsedData = await response.json()
      }

      // Set the decrypted data
      setData(parsedData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(error.message || 'An error occurred while receiving data.')
    }
  }, [url])

  useEffect(() => {
    fetchAndDecrypt()
  }, [fetchAndDecrypt])

  return { loading, error, data, fetchAndDecrypt }
}

export default useFetchAndDecrypt
